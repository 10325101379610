.home .ant-row{
    /* padding-bottom: 3vh; */

}
/* .home .ant-col{
    display: flex;
} */

/* .home .ant-flex{
    align-items: center;
} */

/* .ant-tabs-tab-btn h3{
    padding-top: 20px;
} */
.ant-layout-header {
    background:#fff;
    padding: 0 2.083vw;
}
/* .ant-tabs-card >.ant-tabs-nav .ant-tabs-tab{
    border: none ;
    padding: 1px 0.833vw 0px;
} */
/* .ant-layout-header {
    padding: 0 0 0 20px ;
} */
.ant-tabs .ant-tabs-tab{
    padding: 0;
}
.ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 1.667vw;
}
.ant-picker-time-panel{
    display: none;
}
.ant-tabs-top >.ant-tabs-nav::before {
    border-bottom: none;
}
.ant-tabs-nav-operations, .ant-tabs-nav-operations-hidden {
    display: none !important;
}
